import React, { useState } from "react";
import useStyles from "./styles";
import closedBoxImage from "../../../../assets/images/closed-box-image.png";
import { Button, Typography, Radio } from "@material-ui/core";
import plans, { PlanItem } from "../../../../constants/plans";

interface IAdditionalBoxesStepProps {
  selectedPlan: PlanItem;
  selectedMonth: number;
  onAdvanceStep: Function;
}

interface IAdditionalBoxItemProps {
  month: number;
  value: number;
  disabled: boolean;
  selected: boolean;
  onSelectAdditionalBox: Function;
}

function AdditionalBoxItem(props: IAdditionalBoxItemProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.additionalBoxItem}
      style={{
        opacity: props.disabled ? 0.6 : 1,
      }}
    >
      <Radio
        checked={props.selected}
        disabled={props.disabled}
        onClick={() => props.onSelectAdditionalBox(props.month)}
      />
      <div className={classes.boxDetailsArea}>
        <img src={closedBoxImage} />
        <Typography className={classes.boxDetailText}>
          Box {props.month}
        </Typography>
      </div>
    </div>
  );
}

function AdditionalBoxesStep(props: IAdditionalBoxesStepProps) {
  const classes = useStyles();
  const [selectedAdditionalBoxesMonths, setSelectedAdditionalBoxesMonths] =
    useState([props.selectedMonth] as number[]);

  function isAdditionalBoxSelectionEnabled(month: number): boolean {
    if (month === props.selectedMonth - 1) return true;

    return selectedAdditionalBoxesMonths.some(
      (additionalBoxMonth) => additionalBoxMonth === month + 1
    );
  }

  function onSelectAdditionalBox(month: number): void {
    if (!isAlreadySelectedMonth(month)) {
      const updatedSelectedBoxes = [...selectedAdditionalBoxesMonths];
      updatedSelectedBoxes.push(month);
      setSelectedAdditionalBoxesMonths(updatedSelectedBoxes);
    } else {
      setSelectedAdditionalBoxesMonths(
        selectedAdditionalBoxesMonths.filter(
          (selectedMonth) => selectedMonth !== month
        )
      );
    }
  }

  function isAlreadySelectedMonth(month: number): boolean {
    return selectedAdditionalBoxesMonths.includes(month);
  }

  return (
    <div className={classes.additionalBoxesStep}>
      <div>
        <Typography className={classes.stepInfoText} variant="h5">
          É necessário escolher as Boxes pela ordem, depois você receberá um
          e-mail com mais informações sobre pagamento!
        </Typography>
      </div>
      <div className={classes.boxesArea}>
        {props.selectedPlan.months
          .filter((month, index) => month.month < props.selectedMonth)
          .reverse()
          .map((box, index) => (
            <AdditionalBoxItem
              month={box.month}
              value={box.value.subscription}
              disabled={!isAdditionalBoxSelectionEnabled(box.month)}
              selected={selectedAdditionalBoxesMonths.includes(box.month)}
              onSelectAdditionalBox={onSelectAdditionalBox}
            />
          ))}
      </div>
      <div className={classes.submitButtonArea}>
        <Button
          variant="contained"
          onClick={() =>
            props.onAdvanceStep({
              selectedBoxes: selectedAdditionalBoxesMonths,
            })
          }
          className={classes.noInterestButton}
        >
          NÃO TENHO INTERESSE
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            props.onAdvanceStep({
              selectedBoxes: selectedAdditionalBoxesMonths,
            })
          }
          className={classes.submitButton}
        >
          Tenho interesse
        </Button>
      </div>
    </div>
  );
}

export default AdditionalBoxesStep;
