import React, { useState } from "react";
import plans, { PlanItem } from "../../constants/plans";
import BoxItem from "./box-item/BoxItem";
import useStyles from "./styles";
import singleBoxImage from "../../assets/images/single-box-image.png";
import PlanSelectorDialog from "../plan-selector-dialog/PlanSelectorDialog";
import { navigateToLink } from "../../helpers/navigation-helper/navigation-helper";
import { SINGLE_SHOP_WEBSITE_URL } from "../../constants/single-shop-website-url";
import eventService from "../../services/event-service/event-service";
import EventTypes from "../../constants/event-types";
import WaitListDialog from "../wait-list-dialog/WaitListDialog";
import dataService from "../../services/data-service/data-service";
import { ISetting } from "../../interfaces/setting";

function BoxesContainer() {
  const classes = useStyles();

  const [selectedPlan, setSelectedPlan] = useState(plans.COMPLETE);
  const [planSelectorDialogOpen, setPlanSelectorDialogOpen] = useState(false);

  async function handlePlanClick(selectedPlan: any): Promise<void> {
    const settings = await dataService.getSettings();
    const newSubscriptionsSetting = getSetting("new-subscriptions", settings);

    if (newSubscriptionsSetting?.value) {
      setSelectedPlan(selectedPlan);
      setPlanSelectorDialogOpen(true);
    } else {
      openWaitListDialog();
    }
  }

  function getSetting(settingId: string, settings: ISetting[]): ISetting {
    return settings.find((setting) => setting.id === settingId);
  }

  function openWaitListDialog(): void {
    eventService.emitEvent(EventTypes.OPEN_WAIT_LIST_DIALOG);
  }

  return (
    <div className={classes.boxesContainer}>
      <div className={classes.boxItemContainer}>
        <BoxItem
          boxName="avulsa"
          initialValue={88.8}
          boxImage={singleBoxImage}
          boxSubtitle="a partir de:"
          buttonText="Comprar"
          boxHelperText="receba uma box avulsa"
          onClick={() => navigateToLink(SINGLE_SHOP_WEBSITE_URL)}
        />
      </div>
      <div className={classes.boxItemContainer}>
        <BoxItem
          boxName={plans.COMPLETE.boxName}
          highlighted
          initialValue={plans.COMPLETE.initialValue}
          boxImage={plans.COMPLETE.boxImage}
          onClick={() => handlePlanClick(plans.COMPLETE)}
        />
      </div>
      <div className={classes.boxItemContainer}>
        <BoxItem
          boxName={plans.BASIC.boxName}
          initialValue={plans.BASIC.initialValue}
          boxImage={plans.BASIC.boxImage}
          onClick={() => handlePlanClick(plans.BASIC)}
        />
      </div>

      <PlanSelectorDialog
        open={planSelectorDialogOpen}
        onClose={() => setPlanSelectorDialogOpen(false)}
        initialPlan={selectedPlan}
      />

      <WaitListDialog />
    </div>
  );
}

export default BoxesContainer;
