import { Dialog, Divider, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import { PlanItem } from "../../../constants/plans";
import { navigateToPage } from "../../../helpers/navigation-helper/navigation-helper";
import eventService from "../../../services/event-service";
import SignupForm from "../signup-form";
import SignupProgressStepper from "../signup-progress-stepper/SignupProgressStepper";
import useStyles from "./styles";

interface ISignupDialogProps {
  open: boolean;
  onClose: Function;
  selectedPlan: PlanItem;
  selectedMonth: number;
}

function SignupDialog(props: ISignupDialogProps) {
  const classes = useStyles();

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.TERMS_AND_CONDITIONS_ACCEPTED, () => {
      setTermsAndConditionsAccepted(true);
    });

    return () =>
      eventService.removeListener(
        EventTypes.TERMS_AND_CONDITIONS_ACCEPTED,
        () => setTermsAndConditionsAccepted(false)
      );
  }, []);

  useEffect(() => {
    if (!props.open) {
      setTimeout(() => {
        setTermsAndConditionsAccepted(false);
      }, 200);
    }
  }, [props.open]);

  function closeDialog(): void {
    props.onClose();
  }

  function subscriptionCreated(): void {
    navigateToPage("/assinatura-recebida");
    props.onClose();
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={props.open}
      onClose={closeDialog}
      PaperProps={{
        style: {
          borderRadius: 40,
        },
      }}
    >
      <div className={classes.dialogContent}>
        <div className={classes.imageArea}></div>
        <div className={classes.rightArea}>
          <div className={classes.topArea}>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          {termsAndConditionsAccepted ? (
            <div className={classes.stepperArea}>
              <SignupProgressStepper selectedMonth={props.selectedMonth} />
            </div>
          ) : null}
          {termsAndConditionsAccepted ? (
            <Divider className={classes.divider} />
          ) : null}
          <div className={classes.formArea}>
            <SignupForm
              selectedMonth={props.selectedMonth}
              selectedPlan={props.selectedPlan}
              subscriptionCreated={subscriptionCreated}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SignupDialog;
