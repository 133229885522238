import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  additionalBoxesStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  additionalBoxItem: {
    display: "flex",
    alignItems: "center",
  },
  boxDetailsArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxDetailText: {
    color: "#7C8999",
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
  },
  stepInfoText: {
    marginTop: 10,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#7C8999",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize,
      textAlign: "center",
    },
  },
  boxesArea: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  submitButton: {
    width: 169,
    backgroundColor: "#57BEBF",
    color: "#FFF !important",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  noInterestButton: {
    width: 243,
    backgroundColor: "#FFF",
    color: "#57BEBF",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
}));

export default useStyles;
