import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import EventTypes from "../../constants/event-types";
import eventService from "../../services/event-service/event-service";
import useStyles from "./styles";
import { navigateToLink } from "../../helpers/navigation-helper/navigation-helper";

function WaitListDialog() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_WAIT_LIST_DIALOG, () => {
      setOpen(true);
    });
  }, []);

  function onClose(): void {
    setOpen(false);
  }

  function navigateToWaitList(): void {
    navigateToLink(
      "https://smartbabybox.us6.list-manage.com/subscribe?u=78278f49c572cd7bd40e8b274&id=a6b43a2deb"
    );
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          borderRadius: 40,
        },
      }}
    >
      <DialogContent className={classes.waitListDialogContent}>
        <div className={classes.headerArea}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.mainArea}>
          <Typography variant="h4" className={classes.titleText}>
            Nesse momento as assinaturas estão suspensas!
          </Typography>
          <Typography variant="h6" className={classes.contentText}>
            Mas você pode se inscrever na nossa Lista de Espera e ser avisada
            sobre quando a gente voltar.
          </Typography>
          <Hidden xsDown>
            <div className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                className={classes.backToSiteButton}
                onClick={onClose}
              >
                Voltar ao Site
              </Button>
              <Button
                variant="outlined"
                className={classes.goToWaitListButton}
                onClick={navigateToWaitList}
              >
                Lista de Espera
              </Button>
            </div>
          </Hidden>
          <Hidden smUp>
            <Button
              variant="contained"
              className={classes.mobileWaitListButton}
              onClick={navigateToWaitList}
            >
              Lista de Espera
            </Button>
          </Hidden>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default WaitListDialog;
