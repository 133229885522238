import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  waitListDialogContent: {
    width: 863,
    height: 428,
    display: "flex",
    flexDirection: "column",
    borderRadius: 40,
    backgroundColor: "#FAFAFA",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: 528,
    },
  },
  headerArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  closeButton: {
    backgroundColor: "#57BEBF",
    color: "#FFF",
    width: 40,
    height: 40,
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  mainArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 56,
    paddingRight: 56,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  titleArea: {
    width: "100%",
  },
  titleText: {
    color: "#57BEBF",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contentText: {
    color: "#7C8999",
    fontFamily: "Roboto",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 80,
    display: "flex",
    justifyContent: "center",
  },
  backToSiteButton: {
    width: 277,
    height: 62,
    borderTopLeftRadius: 52,
    borderBottomLeftRadius: 52,
    color: "#57BEBF",
    fontWeight: 700,
    fontSize: theme.typography.h6.fontSize,
    borderColor: "transparent",
    backgroundColor: "#FFF",
  },
  goToWaitListButton: {
    width: 277,
    height: 62,
    borderTopRightRadius: 52,
    borderBottomRightRadius: 52,
    backgroundColor: "#57BEBF",
    color: "#FFF",
    fontWeight: 700,
    fontSize: theme.typography.h6.fontSize,
    borderColor: "transparent",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  mobileWaitListButton: {
    marginTop: 60,
    backgroundColor: "#57BEBF",
    color: "#FFF",
    fontWeight: 700,
    fontSize: theme.typography.h6.fontSize,
    height: 60,
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
}));

export default useStyles;
