import {
  Button,
  ClickAwayListener,
  Fade,
  MenuItem,
  Popper,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@material-ui/icons";

interface DefaultSelectProps {
  options: any[];
  selectedOption: any;
  onSelectOption: Function;
  itemObject?: any;
  labelKey?: string;
  isCounter?: boolean;
  counterLabel?: string;
}

function DefaultSelect({
  options,
  selectedOption,
  onSelectOption,
  itemObject,
  labelKey,
  isCounter,
  counterLabel,
}: DefaultSelectProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  function openMenu(event: any): void {
    if (Boolean(anchorEl)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  function isSelectedOption(
    option: any,
    selectedOption: any,
    index: number
  ): boolean {
    if (isCounter) {
      return index + 1 === selectedOption;
    }
    return itemObject[option] === selectedOption;
  }

  return (
    <div>
      <Button
        variant="contained"
        className={classes.defaultSelectButton}
        endIcon={Boolean(anchorEl) ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={(event) => openMenu(event)}
        style={{
          borderBottomLeftRadius: Boolean(anchorEl) ? 0 : 30,
          borderBottomRightRadius: Boolean(anchorEl) ? 0 : 30,
        }}
      >
        {!isCounter
          ? selectedOption[labelKey]
          : `${selectedOption}${counterLabel}`}
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Fade {...TransitionProps}>
              <div className={classes.defaultSelectMenuContainer}>
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    className={classes.defaultSelectOptionItem}
                    onClick={() => {
                      !isCounter
                        ? onSelectOption(itemObject[option])
                        : onSelectOption(index + 1);
                      setTimeout(() => {
                        setAnchorEl(null);
                      }, 150);
                    }}
                  >
                    <Typography
                      className={classes.defaultSelectOptionText}
                      variant="h5"
                    >
                      {!isCounter
                        ? itemObject[option][labelKey]
                        : `${index + 1}${counterLabel}`}
                    </Typography>
                  </MenuItem>
                ))}
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export default DefaultSelect;
