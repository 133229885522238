import { Button, Typography } from "@material-ui/core";
import React from "react";
import EventTypes from "../../../../constants/event-types";
import eventService from "../../../../services/event-service";
import useStyles from "./styles";

function TermsAndConditionsStep() {
  const classes = useStyles();

  function onAcceptTermsAndConditions(): void {
    eventService.emitEvent(EventTypes.TERMS_AND_CONDITIONS_ACCEPTED);
  }
  return (
    <div className={classes.termsAndConditionsStep}>
      <div className={classes.textArea}>
        <Typography variant="h6" className={classes.paragraph}>
          Estamos felizes em poder acompanhar o seu bebê no primeiro ano de
          vida, mas primeiro veja sobre a nossa política de cancelamento:
        </Typography>
        <Typography variant="h6" className={classes.paragraph}>
          Após o prazo do Art. 49 do CDC, caso você deseje cancelar a
          assinatura, deverá solicitar com antecedência mínima de 30 dias, dessa
          forma a mensalidade seguinte será cobrada e a próxima Box, enviada.
        </Typography>
        <Typography variant="h6" className={classes.paragraph}>
          Caso o cancelamento seja feito sem o aviso prévio, será cobrada uma
          multa no valor de uma mensalidade. Isso porque assim que enviamos uma
          Box, a próxima já entra em produção com muito carinho, para que chegue
          perfeita até você!
        </Typography>
      </div>
      <div className={classes.submitButtonArea}>
        <Button
          variant="contained"
          className={classes.submitButton}
          onClick={onAcceptTermsAndConditions}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}

export default TermsAndConditionsStep;
