import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  planSelectorDialogContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 122,
    paddingRight: 122,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  planSelectorDialogHeaderArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  titleArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    fontFamily: "Frenchpress, Roboto",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
      textAlign: "center",
    },
  },
  subtitleText: {
    fontSize: theme.typography.h5.fontSize,
    fontFamily: "Dosis, Roboto",
    fontWeight: 700,
    color: theme.palette.text.primary,
    letterSpacing: "4%",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body1.fontSize,
      textAlign: "center",
    },
  },
  closeButtonArea: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    backgroundColor: "#FFF",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "#FFF",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 16,
      width: 24,
      height: 24,
      top: 16,
    },
  },
  planSelectorContentArea: {
    flex: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      flex: 0,
    },
  },
  planInfoArea: {
    display: "flex",
    flexDirection: "column",
    flex: 0.4,
    justifyContent: "space-between",
    height: "100%",
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: "100%",
    },
  },
  planSelectorsArea: {
    flex: 1,
    marginLeft: 84,
    marginTop: 48,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  planInfosContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  planInfoContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 48,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
      marginTop: 24,
    },
  },
  planInfoContainerTitleText: {
    fontFamily: "Frenchpress, Roboto",
  },
  planInfoContainerDivider: {
    height: 2.3,
    backgroundColor: theme.palette.text.primary,
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  planValueText: {},
  planValueHighlightedText: {
    fontSize: theme.typography.h2.fontSize,
    WebkitTextStroke: "1px",
  },
  planValueInstallment: {
    letterSpacing: "4%",
    WebkitTextStroke: "1px",
  },
  selectorsListArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  planBoxImageArea: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  planBoxImage: {
    width: 600,
    height: 350,
  },
  submitButtonArea: {
    display: "flex",
    alignItems: "center",
    marginTop: 36,
    [theme.breakpoints.down("sm")]: {},
  },
  submitButton: {
    width: 232,
    backgroundColor: "#F2AF2F",
    color: theme.palette.text.primary,
    fontSize: 24,
    "&:hover": {
      backgroundColor: "#F2AF2F",
    },
  },
  selectorContainer: {
    marginLeft: 22,
    marginRight: 22,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  mobileSignupDrawer: {
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
  },
  mobileSignupDrawerTopArea: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 20,
  },
}));

export default useStyles;
