import { Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import signupStepsImport from "../../../constants/signup-steps";
import ISignupStep from "../../../interfaces/signup-step";
import eventService from "../../../services/event-service";
import useStyles from "./styles";

interface ISignupProgressStepperProps {
  selectedMonth: number;
}

interface IStepItemProps {
  step: ISignupStep;
  isSelected: boolean;
  index: number;
}

function StepItem(props: IStepItemProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.stepItem}>
      <div
        className={classes.stepNumberContainer}
        style={{
          backgroundColor: props.isSelected
            ? "#57BEBF"
            : theme.palette.text.secondary,
        }}
      >
        <Typography className={classes.stepNumberText} variant="body1">
          {props.index + 1}
        </Typography>
      </div>
      <Typography className={classes.stepLabelText} variant="h6">
        {props.step.label}
      </Typography>
    </div>
  );
}

function SignupProgressStepper(props: ISignupProgressStepperProps) {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [signupSteps, setSignupSteps] = useState([...signupStepsImport]);

  useEffect(() => {
    eventService.addListener(EventTypes.SIGNUP_STEP_CHANGED, (step: number) =>
      setCurrentStep(step)
    );

    return () =>
      eventService.removeListener(EventTypes.SIGNUP_STEP_CHANGED, () => {});
  }, []);

  useEffect(() => {
    if (props.selectedMonth === 1) {
      setSignupSteps(signupSteps.filter((signupStep, index) => index !== 0));
    } else {
      setSignupSteps([...signupStepsImport]);
    }
  }, [props.selectedMonth]);

  return (
    <div className={classes.signupProgressStepper}>
      {signupSteps.map((step, index) => (
        <StepItem
          step={step}
          isSelected={currentStep >= index}
          index={index}
          key={index}
        />
      ))}
    </div>
  );
}

export default SignupProgressStepper;
