import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  defaultSelectButton: {
    width: 210,
    height: 60,
    borderRadius: 30,
    backgroundColor: "#FFF",
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "#FFF",
    }
  },
  defaultSelectMenuContainer: {
    backgroundColor: theme.palette.secondary.main,
    width: 210,
    maxHeight: 326,
    overflow: 'auto',
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    WebkitBoxShadow: "12px 5px 15px 5px rgba(0,0,0,0.08)",
    boxShadow: "12px 5px 15px 5px rgba(0,0,0,0.08)",
  },
  defaultSelectOptionItem: {
    border: '1px solid #F1A2B1',
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    "&:last-child": {
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      height: 58
    }
  },
  defaultSelectOptionText: {
    color: theme.palette.text.primary,
    fontFamily: 'Dosis, Roboto',
    textTransform: 'uppercase',
    fontWeight: 800,
  }
}));

export default useStyles;