import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import plans, { PlanItem } from "../../constants/plans";
import { navigateToPage } from "../../helpers/navigation-helper/navigation-helper";
import DefaultSelect from "../default-select/DefaultSelect";
import { PlanDisplayerItemMonthItem } from "../plans-displayer/plan-displayer-item/PlanDisplayerItem";
import SignupDialog from "../signup/signup-dialog/SignupDialog";
import SignupForm from "../signup/signup-form";
import useStyles from "./styles";

interface PlanSelectorDialogProps {
  open: boolean;
  onClose: Function;
  initialPlan?: PlanItem;
}

function PlanSelectorDialog({
  open,
  onClose,
  initialPlan,
}: PlanSelectorDialogProps) {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState(plans.COMPLETE as any);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [signupFormOpen, setSignupFormOpen] = useState(false);

  useEffect(() => {
    if (initialPlan) {
      setSelectedPlan(initialPlan);
    }
  }, [initialPlan]);

  function getPlanValue(): number {
    return selectedPlan.months[selectedMonth - 1].value.subscription;
  }

  function getPlanName(): string {
    return selectedPlan.boxName;
  }

  function getSubmitButtonText(): string {
    return "Assine já";
  }

  function handleSubmit(): void {
    setSignupFormOpen(true);
  }

  function subscriptionCreated(): void {
    navigateToPage("/assinatura-recebida");
    setSignupFormOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullScreen
      disableEnforceFocus
    >
      <DialogContent className={classes.planSelectorDialogContent}>
        <div className={classes.planSelectorDialogHeaderArea}>
          <div className={classes.titleArea}>
            <Typography variant="h1" className={classes.titleText}>
              escolha sua assinatura
            </Typography>
            <Typography className={classes.subtitleText}>
              a partir de qual mês você quer assinar?
            </Typography>
          </div>
          <div className={classes.closeButtonArea}>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.planSelectorContentArea}>
          <div className={classes.planInfoArea}>
            <div className={classes.planInfosContainer}>
              <div className={classes.planInfoContainer}>
                <Typography
                  variant="h3"
                  className={classes.planInfoContainerTitleText}
                >
                  {getPlanName()}
                </Typography>
                <Divider className={classes.planInfoContainerDivider} />
                <Typography variant="h5" className={classes.planValueText}>
                  R$
                  <span className={classes.planValueHighlightedText}>
                    {
                      parseFloat(getPlanValue())
                        .toFixed(2)
                        .toString()
                        .split(".")[0]
                    }
                  </span>
                  ,
                  {
                    parseFloat(getPlanValue())
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  }
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.planValueInstallment}
                >
                  mensalidade a ser paga até a box 12
                </Typography>
              </div>
              <div className={classes.planInfoContainer}>
                <Typography
                  variant="h3"
                  className={classes.planInfoContainerTitleText}
                >
                  na sua primeira box:
                </Typography>
                <Divider className={classes.planInfoContainerDivider} />
                <div>
                  {selectedPlan.months[selectedMonth - 1].items.map(
                    (item: string, index: number) => (
                      <PlanDisplayerItemMonthItem item={item} key={index} />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className={classes.submitButtonArea}>
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={() => handleSubmit()}
              >
                {getSubmitButtonText()}
              </Button>
            </div>
          </div>
          <div className={classes.planSelectorsArea}>
            <div className={classes.selectorsListArea}>
              <div className={classes.selectorContainer}>
                <DefaultSelect
                  options={Object.keys(plans)}
                  selectedOption={selectedPlan}
                  onSelectOption={(plan: PlanItem) => setSelectedPlan(plan)}
                  itemObject={plans}
                  labelKey="boxName"
                />
              </div>
              <div className={classes.selectorContainer}>
                <DefaultSelect
                  options={selectedPlan.months.slice(0, 11)}
                  selectedOption={selectedMonth}
                  onSelectOption={(month: number) => setSelectedMonth(month)}
                  itemObject={selectedPlan.months}
                  labelKey="label"
                  isCounter
                  counterLabel="º mês"
                />
              </div>
            </div>
            <Hidden smDown>
              <div className={classes.planBoxImageArea}>
                <img
                  src={selectedPlan.boxImage}
                  alt="Caixa"
                  className={classes.planBoxImage}
                />
              </div>
            </Hidden>
          </div>
        </div>
      </DialogContent>
      <Hidden smDown>
        <SignupDialog
          open={signupFormOpen}
          onClose={() => setSignupFormOpen(false)}
          selectedPlan={selectedPlan}
          selectedMonth={selectedMonth}
        />
      </Hidden>
      <Hidden mdUp>
        <Drawer
          anchor="bottom"
          open={signupFormOpen}
          onClose={() => setSignupFormOpen(false)}
          classes={{
            paper: classes.mobileSignupDrawer,
          }}
        >
          <div className={classes.mobileSignupDrawerTopArea}>
            <IconButton onClick={() => setSignupFormOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <SignupForm
            selectedPlan={selectedPlan}
            selectedMonth={selectedMonth}
            subscriptionCreated={subscriptionCreated}
          />
        </Drawer>
      </Hidden>
    </Dialog>
  );
}

export default PlanSelectorDialog;
