import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  termsAndConditionsStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  paragraph: {
    marginTop: 30,
    color: "#7C8999",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  submitButtonArea: {
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  submitButton: {
    width: 162,
    backgroundColor: "#57BEBF",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
}));

export default useStyles;
