import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import { PlanItem } from "../../../constants/plans";
import signupStepsImport from "../../../constants/signup-steps";
import { navigateToLink } from "../../../helpers/navigation-helper/navigation-helper";
import dataService from "../../../services/data-service/data-service";
import eventService from "../../../services/event-service";
import useStyles from "./styles";
import TermsAndConditionsStep from "./terms-and-conditions-step/TermsAndConditionsStep";

interface ISignupFormProps {
  selectedPlan: PlanItem;
  selectedMonth: number;
  subscriptionCreated: Function;
}

function SignupForm(props: ISignupFormProps) {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [subscription, setSubscription] = useState({} as any);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [signupSteps, setSignupSteps] = useState([...signupStepsImport]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.TERMS_AND_CONDITIONS_ACCEPTED, () => {
      setTermsAndConditionsAccepted(true);
    });

    return () => {
      setTermsAndConditionsAccepted(false);
      eventService.removeListener(
        EventTypes.TERMS_AND_CONDITIONS_ACCEPTED,
        () => setTermsAndConditionsAccepted(false)
      );
    };
  }, []);

  useEffect(() => {
    if (props.selectedMonth === 1) {
      setSignupSteps(signupSteps.filter((signupStep, index) => index !== 0));
      setSubscription({
        selectedBoxes: [1],
      });
    } else {
      setSignupSteps([...signupStepsImport]);
    }
  }, [props.selectedMonth]);

  useEffect(() => {
    eventService.emitEvent(EventTypes.SIGNUP_STEP_CHANGED, [currentStep]);
  }, [currentStep]);

  let StepComponent = signupSteps.find(
    (step, index) => index === currentStep
  ).Component;

  async function onAdvanceStep(formData: any): Promise<void> {
    setSubscription({
      ...subscription,
      ...formData,
    });
    if (currentStep === signupSteps.length - 1) {
      setIsLoading(true);
      await dataService.submitForm(
        formatSubscriptionObject({ ...subscription, ...formData })
      );
      window.location.replace(
        props.selectedPlan.months[props.selectedMonth - 1].paymentUrl
      );
      setIsLoading(false);
      // props.subscriptionCreated();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  function formatSubscriptionObject(subscription) {
    return {
      selectedBoxes: subscription.selectedBoxes,
      type: props.selectedPlan.type,
      customer: {
        name: subscription.name,
        babyName: subscription.babyName,
        babyBirthDate: subscription.babyBirthDate,
        email: subscription.email,
        cpf: subscription.cpf,
        phoneNumber: subscription.phoneNumber,
        address: subscription.address,
      },
    };
  }

  return (
    <div className={classes.signupForm}>
      <div className={classes.titleArea}>
        <Typography variant="h4" className={classes.titleText}>
          {termsAndConditionsAccepted ? "Preencha os campos abaixo." : "Oba!"}
        </Typography>
      </div>
      <div>
        {termsAndConditionsAccepted ? (
          <StepComponent
            onAdvanceStep={onAdvanceStep}
            selectedPlan={props.selectedPlan}
            selectedMonth={props.selectedMonth}
            isLoading={isLoading}
          />
        ) : (
          <TermsAndConditionsStep />
        )}
      </div>
    </div>
  );
}

export default SignupForm;
