import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  personalInfoStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  stepInfoText: {
    marginTop: 10,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#7C8999",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize,
      textAlign: "center",
    },
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 40,
  },
  formArea: {
    flex: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
    marginRight: 5,
  },
  textInput: {
    marginBottom: 10,
    minHeight: 80,
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  submitButton: {
    width: 162,
    backgroundColor: "#57BEBF",
    color: "#FFF !important",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
}));

export default useStyles;
