import * as Yup from "yup";
import dataService from "../services/data-service/data-service";
import cpf from "cpf";

const yupValidations = {
  email: Yup.string()
    .required("Preencha seu e-mail.")
    .email("Preencha um e-mail válido.")
    .test(
      "email-test",
      "Este e-mail já está sendo utilizado.",
      async function (email: string) {
        const isExistingEmail = await dataService.validateEmail(email);
        return !isExistingEmail;
      }
    ),
  cpf: Yup.string()
    .required("Preencha seu CPF.")
    .matches(/^\d{3}.?\d{3}.?\d{3}-?\d{2}$/, "Digite um CPF válido.")
    .test("cpf-test", "Digite um CPF válido.", function (cpfInput: string) {
      return cpf.isValid(cpfInput);
    }),
  babyBirthDate: Yup.string()
    .required("Preencha a data de nascimento do bebê.")
    .matches(
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
      "Preencha uma data válida."
    ),
};
export default yupValidations;
