import { ISetting } from "../../interfaces/setting";

class DataService {
  async validateEmail(email: string): Promise<boolean> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/subscriptions/validate-email/${email}`
      );
      const isExistingEmail: boolean = await response.json();
      return isExistingEmail;
    } catch (error) {
      console.error(error);
    }
  }

  async submitForm(data: any): Promise<void> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/subscriptions`,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  public async getSettings(): Promise<ISetting[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings`
      );
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new DataService();
