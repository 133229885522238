import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  addressStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  formArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  textInput: {
    marginBottom: 10,
    minHeight: 80,
    marginRight: 5,
    marginLeft: 5,
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  submitButton: {
    width: 301,
    backgroundColor: "#57BEBF",
    color: "#FFF !important",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  row: {
    display: "flex",
  },
  loadingIndicator: {
    marginLeft: 8,
  },
}));

export default useStyles;
