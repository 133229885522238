import ISignupStep from "../interfaces/signup-step";
import PersonalInfoStep from "../components/signup/signup-form/personal-info-step";
import BabyInfoStep from "../components/signup/signup-form/baby-info-step";
import AddressStep from "../components/signup/signup-form/address-step";
import AdditionalBoxesStep from "../components/signup/signup-form/additional-boxes-step";

const signupSteps: ISignupStep[] = [
  {
    label: "Promoção",
    Component: AdditionalBoxesStep,
  },
  {
    label: "Seus dados",
    Component: PersonalInfoStep,
  },
  {
    label: "Seu bebê",
    Component: BabyInfoStep,
  },
  {
    label: "Endereço",
    Component: AddressStep,
  },
];

export default signupSteps;
