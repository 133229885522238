import { Button, Divider, Typography, useTheme } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

interface BoxItemProps {
  boxName: string;
  highlighted?: boolean;
  initialValue?: number;
  boxImage: string;
  boxSubtitle?: string;
  boxHelperText?: string;
  buttonText?: string;
  onClick: Function;
}

function BoxItem({
  boxName,
  highlighted = false,
  initialValue,
  boxImage,
  boxSubtitle = "mensalidade a partir de:",
  boxHelperText = "receba uma box a cada mês",
  onClick,
  buttonText = "Assinar",
}: BoxItemProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={highlighted ? classes.highlightedBoxItem : classes.boxItem}>
      <div className={classes.boxImageArea}>
        <img src={boxImage} alt="Box" className={classes.boxImage} />
      </div>
      <div className={classes.boxNameArea}>
        <Typography
          variant="h3"
          color="inherit"
          className={classes.boxNameText}
        >
          {boxName}
        </Typography>
      </div>
      <Divider className={classes.divider}></Divider>
      <div>
        <Typography variant="h6" className={classes.fromValueText}>
          {boxSubtitle}
        </Typography>
        <Typography className={classes.boxValueText} variant="h5">
          R${" "}
          <span className={classes.boxValueHighlight}>
            {parseFloat(initialValue).toFixed(2).toString().split(".")[0]}
          </span>
          ,{parseFloat(initialValue).toFixed(2).toString().split(".")[1]}
        </Typography>
        <Typography variant="h6" className={classes.boxHelperText}>
          {boxHelperText}
        </Typography>
      </div>
      <div>
        <Button
          variant="contained"
          className={classes.boxOptionsButton}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default BoxItem;
