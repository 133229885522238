import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  signupForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  titleArea: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100",
      justifyContent: "center",
    },
  },
  titleText: {
    color: "#57BEBF",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h5.fontSize,
      textAlign: "center",
    },
  },
}));

export default useStyles;
