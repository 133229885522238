import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  boxesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      height: "100%"
    }
  },
  boxItemContainer: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: "30vh",
    [theme.breakpoints.down("sm")]: {
      "&:last-child": {
        marginBottom: 0
      }
    }
  }
}));

export default useStyles;