import { makeStyles } from "@material-ui/core";
import signupDialogImage from "../../../assets/images/signup-dialog-image.png";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minHeight: 675,
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  imageArea: {
    flex: 0.5,
    backgroundImage: `url(${signupDialogImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  rightArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 32,
  },
  topArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  stepperArea: {
    width: "100%",
    display: "flex",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.text.secondary,
    marginTop: 40,
    marginBottom: 40,
    marginRight: 48,
    marginLeft: 48,
  },
  formArea: {
    flex: 1,
    paddingLeft: 48,
    paddingRight: 48,
  },
}));

export default useStyles;
