import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  boxItem: {
    width: 309,
    height: 400,
    borderRadius: 10,
    backgroundColor: "#F3F3F3",
    color: "#7C8999",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 36,
    paddingRight: 36,
  },
  highlightedBoxItem: {
    width: 309,
    height: 400,
    borderRadius: 10,
    backgroundColor: "#57BEBF",
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 36,
    paddingRight: 36,
  },
  boxImageArea: {
    marginTop: -100,
  },
  boxImage: {
    width: 308,
    height: 208
  },
  boxNameArea: {
    color: "inherit",
  },
  fromValueText: {
    textAlign: 'center',
    letterSpacing: "4%",
    fontWeight: 700,
  },
  boxNameText: {
    fontFamily: 'Frenchpress',
    letterSpacing: '4%',
    WebkitTextStroke: "1.03px",
  },
  divider: {
    width: '100%',
    height: 2.3,
    color: 'inherit',
    marginTop: 20,
    marginBottom: 16,
  },
  boxValueText: {
    fontFamily: 'Dosis, Roboto',
    letterSpacing: '4%',
    fontWeight: 700,
    color: "inherit",
    textAlign: 'center',
  },
  boxValueHighlight: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.secondary.main,
  },
  boxHelperText: {
    color: "inherit",
    fontFamily: 'Dosis',
    textAlign: 'center',
    letterSpacing: "4%",
    fontWeight: 700,
  },
  boxOptionsButton: {
    width: 210,
    height: 60,
    borderRadius: 60,
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    marginTop: 20,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  boxOptionsMenuContainer: {
    backgroundColor: "#FFF",
    width: 210,
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
  },
  boxOptionItem: {
    border: '1px solid #F1A2B1',
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    "&:last-child": {
      borderBottomLeftRadius: 60,
      borderBottomRightRadius: 60,
      height: 58
    }
  },
  boxOptionItemText: {
    color: theme.palette.secondary.main,
    fontFamily: 'Dosis, Roboto',
    textTransform: 'uppercase',
    fontWeight: 800,
  }
}));

export default useStyles;